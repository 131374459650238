<template>
  <div class="text-red-400  text-xs p-1 mt-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormError"
};
</script>
