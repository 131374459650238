<template>
  <div class="loader">Se încarcă...</div>
</template>

<script>
  export default {
    name: 'Spinner'
  }
</script>

<style scoped>
  .loader {
    color: #ffffff;
  }
</style>
