<template>
  <div class="flex items-center gap-4 xl:gap-8">
    <router-link to="/" class="block w-36">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 311 69"
        class="fill-current"
      >
        <path
          d="M33.8 0C15.1 0 0 15.1 0 33.8C0 52.4 15.1 67.6 33.8 67.6C52.5 67.6 67.6 52.5 67.6 33.8C67.5 15.1 52.4 0 33.8 0Z"
        />
        <path
          d="M100.3 29C98 29 96 28.5 94.2 27.6C92.4 26.6 91 25.3 90 23.5C89 21.7 88.5 19.7 88.5 17.4C88.5 15.1 89 13 90 11.3C91 9.49995 92.4 8.19995 94.2 7.19995C96 6.19995 98 5.69995 100.3 5.69995C103.4 5.69995 106 6.49995 108 8.09995V10.5C106.1 8.79995 103.6 7.89995 100.5 7.89995C98.7 7.89995 97.1 8.29995 95.6 9.09995C94.2 9.89995 93.1 11 92.2 12.4C91.4 13.8 91 15.5 91 17.4C91 19.3 91.4 21 92.2 22.4C93 23.8 94.1 24.9 95.6 25.7C97 26.5 98.6 26.7999 100.4 26.7999C102 26.7999 103.4 26.6 104.7 26.2C106 25.8 107.1 25.1 108.2 24.1V26.6C106.4 28.2 103.7 29 100.3 29Z"
        />
        <path
          d="M122.5 29C120.3 29 118.3 28.5 116.5 27.5C114.7 26.5 113.3 25.1 112.3 23.4C111.3 21.6 110.8 19.6 110.8 17.3C110.8 15 111.3 13 112.3 11.2C113.3 9.39998 114.7 8.09998 116.5 7.09998C118.3 6.09998 120.3 5.59998 122.5 5.59998C124.7 5.59998 126.7 6.09998 128.5 7.09998C130.3 8.09998 131.7 9.49998 132.7 11.2C133.7 13 134.2 15 134.2 17.3C134.2 19.6 133.7 21.6 132.7 23.4C131.7 25.2 130.3 26.5 128.5 27.5C126.7 28.5 124.7 29 122.5 29ZM122.5 26.7C124.2 26.7 125.8 26.3 127.2 25.6C128.6 24.8 129.7 23.7 130.5 22.3C131.3 20.9 131.7 19.2 131.7 17.3C131.7 15.4 131.3 13.8 130.5 12.4C129.7 11 128.6 9.89998 127.2 9.09998C125.8 8.29998 124.3 7.89998 122.5 7.89998C120.8 7.89998 119.2 8.29998 117.8 9.09998C116.4 9.89998 115.3 11 114.5 12.4C113.7 13.8 113.3 15.5 113.3 17.4C113.3 19.3 113.7 21 114.5 22.4C115.3 23.8 116.4 24.9 117.8 25.7C119.3 26.4 120.8 26.7 122.5 26.7Z"
        />
        <path
          d="M136.8 6.09998H139.4L146.9 25L154.4 6.09998H157L148.1 28.5H145.8L136.8 6.09998Z"
        />
        <path d="M160.2 6.09998H162.7V28.5H160.2V6.09998Z" />
        <path
          d="M166.6 6.09998H173.5C175.9 6.09998 178 6.49998 179.7 7.39998C181.5 8.29998 182.8 9.59998 183.7 11.2C184.6 12.9 185.1 14.8 185.1 17.1C185.1 20.8 184 23.6 181.9 25.6C179.8 27.5 176.8 28.4 172.9 28.4H166.5V6.09998H166.6ZM172.8 26.4C176.2 26.4 178.6 25.6 180.3 24.1C181.9 22.5 182.7 20.3 182.7 17.2C182.7 14.4 181.9 12.2 180.4 10.6C178.8 8.99998 176.5 8.19998 173.5 8.19998H169.1V26.3H172.8V26.4Z"
        />
        <path d="M187.7 18H197.5V20.1H187.7V18Z" />
        <path
          d="M205.1 8.89995L200.1 12.5V9.99995L205.3 6.19995H207.5V28.6H205V8.89995H205.1Z"
        />
        <path
          d="M216.4 29C215.3 29 214.4 28.9 213.6 28.7V26.5C214.4 26.8 215.2 26.9 216.2 26.9C218.7 26.9 220.6 26.1 221.9 24.6C223.2 23.1 223.9 20.8 224.1 17.7C223.5 18.6 222.7 19.3 221.6 19.7C220.5 20.2 219.3 20.4 218 20.4C216.6 20.4 215.3 20.1 214.2 19.5C213.1 18.9 212.3 18 211.6 16.9C211 15.8 210.7 14.5 210.7 13.1C210.7 11.7 211 10.4 211.7 9.29995C212.3 8.19995 213.3 7.29995 214.5 6.69995C215.7 6.09995 217.1 5.69995 218.7 5.69995C221.2 5.69995 223.1 6.49995 224.6 8.09995C226.1 9.69995 226.8 12 226.8 15.1C226.8 19.6 225.9 23.1 224.2 25.5C222.4 27.8 219.8 29 216.4 29ZM218.6 18.3C220.2 18.3 221.5 17.8 222.5 16.9C223.5 16 224 14.7 224 13.1C224 12.2 223.8 11.3 223.3 10.5C222.8 9.69995 222.2 9.09995 221.4 8.59995C220.6 8.09995 219.6 7.89995 218.6 7.89995C217 7.89995 215.7 8.39995 214.7 9.39995C213.7 10.4 213.2 11.7 213.2 13.3C213.2 14.9 213.7 16.2 214.7 17.1C215.7 17.8 217 18.3 218.6 18.3Z"
        />
        <path d="M87 62.3H309.8V65.3001H87V62.3Z" />
        <path
          d="M88.6 37.6H91.2L98.7 56.5L106.2 37.6H108.8L99.8 60H97.5L88.6 37.6Z"
        />
        <path d="M112 37.6H114.5V60H112V37.6Z" />
        <path
          d="M118.3 37.6H132.7V39.7H120.8V47.7H129.7V49.8H120.8V57.8H132.7V60H118.3V37.6V37.6Z"
        />
        <path
          d="M141.8 39.7999H134.6V37.7H151.4V39.7999H144.2V60H141.7V39.7999H141.8ZM141.7 62H144.3L141.4 68.3H139.2L141.7 62Z"
        />
        <path d="M154.1 37.6H156.6V60H154.1V37.6Z" />
        <path
          d="M172 37.6H180.3C182.6 37.6 184.5 38.2 185.8 39.3C187.1 40.4 187.8 42 187.8 44.2C187.8 46.4 187.1 48 185.8 49.1C184.5 50.2 182.7 50.7 180.3 50.7H174.5V60H172V37.6ZM180.1 48.7C181.7 48.7 182.9 48.4 183.9 47.7C184.8 47 185.3 45.9 185.3 44.2C185.3 42.5 184.8 41.4 183.9 40.7C183 40 181.7 39.7 180.1 39.7H174.5V48.6H180.1V48.7Z"
        />
        <path d="M190.3 37.6H192.8V60H190.3V37.6Z" />
        <path
          d="M196.7 37.6H211.1V39.7H199.2V47.7H208.1V49.8H199.2V57.8H211.1V60H196.7V37.6Z"
        />
        <path
          d="M214.3 37.6H222.9C225.2 37.6 227 38.1 228.2 39.2C229.4 40.3 230 41.9 230 44C230 45.6 229.5 47 228.6 48.1C227.7 49.2 226.3 49.9 224.6 50.2L229.9 59.8H227.3L222.1 50.3H216.8V60H214.3V37.6V37.6ZM222.6 48.4C224.1 48.4 225.3 48 226.2 47.3C227.1 46.6 227.5 45.5 227.5 44.1C227.5 42.5 227.1 41.4 226.2 40.8C225.3 40.1 224.1 39.8 222.6 39.8H216.8V48.4H222.6Z"
        />
        <path
          d="M233.2 37.6H240.1C242.5 37.6 244.6 38 246.3 38.9C248.1 39.8 249.4 41.1 250.3 42.7C251.2 44.4 251.7 46.3 251.7 48.6C251.7 52.3 250.6 55.1 248.5 57.1C246.4 59 243.4 59.9 239.5 59.9H233.1V37.6H233.2ZM239.4 57.9C242.8 57.9 245.2 57.1 246.9 55.6C248.5 54 249.3 51.8 249.3 48.7C249.3 45.9 248.5 43.7 247 42.1C245.4 40.5 243.1 39.7 240.1 39.7H235.7V57.8H239.4V57.9Z"
        />
        <path
          d="M263.8 60.5C260.9 60.5 258.6 59.8 257.1 58.3C255.6 56.8 254.9 54.7 254.9 52.1V37.6H257.4V52.2C257.4 54.2 258 55.7 259.1 56.7C260.2 57.7 261.8 58.2 263.9 58.2C265.9 58.2 267.5 57.7 268.6 56.7C269.7 55.7 270.3 54.2 270.3 52.2V37.6H272.8V52C272.8 54.7 272 56.8 270.5 58.2C268.9 59.8 266.7 60.5 263.8 60.5Z"
        />
        <path
          d="M282.2 39.7999H275V37.7H291.8V39.7999H284.6V60H282.1V39.7999H282.2Z"
        />
        <path
          d="M294.5 37.6H308.9V39.7H297V47.7H305.9V49.8H297V57.8H308.9V60H294.5V37.6Z"
        />
      </svg>
    </router-link>

    <router-link
      to="/despre"
      :class="inverted ? 'text-white' : 'text-gray-600'"
      class="inline-block py-3 px-2 text-base tracking-wide text-opacity-60 hover:text-opacity-100 leading-5"
      >Despre proiect</router-link
    >

    <router-link
      v-show="nextStoryId"
      :to="`/poveste/${nextStoryId}`"
      :class="inverted ? 'text-white' : 'text-gray-600'"
      class="inline-block py-3 px-2 text-base tracking-wide text-opacity-60 hover:text-opacity-100 leading-5"
      >Altă poveste</router-link
    >
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    inverted: {
      type: Boolean,
      default: true
    },
    nextStoryId: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.router-link-active {
  --tw-text-opacity: 1;
}
</style>
